<template>
  <v-card outlined>
    <v-card-title>Informations internes</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" class="py-0">
            <v-select
              outlined
              dense
              label="Fonction"
              :items="roles"
              v-model="selectedRole"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-text-field
              outlined
              dense
              label="Intitulé de la fonction"
              v-model="notice.role"
              v-if="selectedRole === 'Autre'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-text-field
              v-if="notice.type === 'HiringNotice'"
              outlined
              label="Date d'entrée"
              v-model="displayHiringDate"
              :rules="[validation.date]"
              v-mask="['##.##.####']"
              hint="Format attendu JJ.MM.AAAA"
              dense
          /></v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-text-field
              outlined
              label="Date de sortie"
              v-if="notice.type === 'LeavingNotice'"
              v-model="displayLeavingDate"
              :rules="[validation.date]"
              v-mask="['##.##.####']"
              hint="Format attendu JJ.MM.AAAA"
              dense
              ref="exitDate"
          /></v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-text-field
              v-if="notice.type === 'HiringNotice'"
              outlined
              dense
              label="Taux d'activité"
              v-model="notice.rate"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-radio-group row v-model="selectedUnity">
              <v-radio label="Département" value="department"> </v-radio>
              <v-radio label="Centre" value="center"> </v-radio>
              <v-radio label="Service" value="service"> </v-radio>
              <v-radio label="Support transversal" value="transversalsupport">
              </v-radio>
              <v-radio label="Direction" value="direction"> </v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            class="py-0"
            v-if="selectedUnity === 'department'"
          >
            <v-select
              outlined
              dense
              label="Dépatement"
              :items="departments"
              v-model="notice.properties.department"
            ></v-select
          ></v-col>
          <v-col
            cols="12"
            sm="12"
            class="py-0"
            v-if="selectedUnity === 'center'"
          >
            <v-select
              outlined
              dense
              label="Centre"
              :items="centers"
              v-model="notice.properties.center"
            ></v-select
          ></v-col>
          <v-col
            cols="12"
            sm="12"
            class="py-0"
            v-if="selectedUnity === 'service'"
          >
            <v-select
              outlined
              dense
              label="Service"
              :items="services"
              v-model="notice.properties.service"
            ></v-select
          ></v-col>
          <v-col
            cols="12"
            sm="12"
            class="py-0"
            v-if="selectedUnity === 'transversalsupport'"
          >
            <v-select
              outlined
              dense
              label="Support transversal"
              :items="transversalsupports"
              v-model="notice.properties.transversalsupport"
            ></v-select
          ></v-col>
          <v-col cols="12" sm="12" class="py-0"
            ><v-select
              v-if="notice.type === 'HiringNotice'"
              outlined
              dense
              label="Institut"
              :items="institutes"
              v-model="notice.institute"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" class="py-0"
            ><v-select
              v-if="notice.type === 'HiringNotice'"
              outlined
              dense
              label="Section"
              :items="sections"
              v-model="notice.section"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" class="py-0"
            ><v-select
              v-if="notice.type === 'HiringNotice'"
              outlined
              dense
              label="Unité"
              :items="unities"
              v-model="notice.unity"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" class="py-0"
            ><v-text-field
              outlined
              label="Acronyme"
              v-model="notice.acronym"
              :rules="[validation.creatorTla]"
              :maxlength="3"
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" class="py-0"
            ><v-text-field
              outlined
              label="Login AAI"
              v-model="notice.username"
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" class="py-0">
            <v-autocomplete
              v-model="selected"
              :items="managers"
              :search-input.sync="searchManagers"
              label="Mgt fonctionnel ou opérationnel"
              outlined
              dense
              @input="clearSearchManagers"
              @change="setManagerValues"
              clearable
              no-data-text="Tapez le nom d'une personne..."
            />
          </v-col>
          <v-col cols="12" sm="12" class="py-0"
            ><v-select
              v-if="notice.type === 'HiringNotice'"
              outlined
              dense
              label="Lieu de travail"
              :items="locations"
              v-model="notice.work_location"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" class="py-0"> </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { parse, parseISO, format, isSameDay } from "date-fns";
import { mask } from "vue-the-mask";
import axios from "axios";
import _ from "lodash";

export default {
  directives: { mask },
  props: ["notice", "isCopy"],
  async mounted() {
    if (this.isCopy) {
      await this.$refs.exitDate.focus();
    }
    if (this.notice.manager) {
      this.managers = [
        {
          text: this.notice.manager,
          value: { email: this.notice.properties.manager_email },
        },
      ];
      this.selected = { email: this.notice.properties.manager_email };
    }
    if (this.notice.hiring_date) {
      this.displayHiringDate = format(
        parseISO(this.notice.hiring_date),
        "dd.MM.yyyy"
      );
    }
    if (this.notice.leaving_date) {
      this.displayLeavingDate = format(
        parseISO(this.notice.leaving_date),
        "dd.MM.yyyy"
      );
    }
    if (this.notice.role && this.roles.indexOf(this.notice.role) > -1) {
      this.selectedRole = this.notice.role;
    } else {
      if (this.notice.role) {
        this.selectedRole = "Autre";
      } else {
        this.selectedRole = undefined;
      }
    }
    if (this.notice.properties.service) {
      this.selectedUnity = "service";
    }
    if (this.notice.properties.direction) {
      this.selectedUnity = "direction";
    }
    if (this.notice.properties.department) {
      this.selectedUnity = "department";
    }
    if (this.notice.properties.department) {
      this.selectedUnity = "department";
    }
    if (this.notice.properties.center) {
      this.selectedUnity = "center";
    }
    if (this.notice.properties.transversalsupport) {
      this.selectedUnity = "transversalsupport";
    }
  },
  methods: {
    async queryPicker(v, key) {
      this.loading = true;
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_PEOPLEPICKER_URI}/search?s=${v}`,
        headers: {
          "x-api-key": process.env.VUE_APP_PEOPLEPICKER_KEY,
        },
      });
      contacts.map((c) =>
        this[key].push({
          text: `${c.firstname} ${c.lastname}`,
          value: c,
        })
      );
      this[key] = _.uniqBy(this[key], "value.email");
      this.loading = false;
    },
    clearSearchManagers() {
      this.searchManagers = null;
    },
    setManagerValues(val) {
      if (val) {
        this.notice.manager = `${val.firstname} ${val.lastname}`;
        this.notice.properties.manager_email = val.email;
      } else {
        this.notice.manager = val;
        this.notice.properties.manager_email = val;
        this.notice.properties.notify_manager = false;
        this.searchManagers = null;
        this.managers = [];
      }
    },
  },
  watch: {
    selectedRole(after, before) {
      if (this.selectedRole === "Autre" && before !== "") {
        this.notice.role = "";
      } else {
        if (this.selectedRole !== "Autre" && this.notice.role !== "") {
          this.notice.role = after;
        }
      }
    },
    displayHiringDate: function (after) {
      if (after.length === 10) {
        this.notice.hiring_date = format(
          parse(after, "dd.MM.yyyy", new Date()),
          "yyyy-MM-dd"
        );
      }
    },
    "notice.hiring_date": function (after, before) {
      if (after && !isSameDay(parseISO(after), parseISO(before))) {
        this.displayHiringDate = format(parseISO(after), "dd.MM.yyyy");
      }
    },

    displayLeavingDate: function (after) {
      if (after.length === 10) {
        this.notice.leaving_date = format(
          parse(after, "dd.MM.yyyy", new Date()),
          "yyyy-MM-dd"
        );
      }
    },
    "notice.leaving_date": function (after, before) {
      if (after && !isSameDay(parseISO(after), parseISO(before))) {
        this.displayLeavingDate = format(parseISO(after), "dd.MM.yyyy");
      }
    },
    "notice.acronym": function (val) {
      this.notice.acronym = val.toUpperCase();
    },
    searchManagers: _.debounce(function (val) {
      val && val !== this.selected && this.queryPicker(val, "managers");
    }, 300),
    selectedUnity(after, before) {
      this.notice.properties[before] = null;
      if (after === "direction") {
        this.notice.properties.direction = "Direction";
      }
    },
  },
  data: () => ({
    selectedRole: "",
    selectedUnity: "department",
    displayHiringDate: "",
    displayLeavingDate: "",
    searchManagers: null,
    managers: [],
    selected: {},
    validation: {
      creatorTla: (value) => {
        const pattern = /^[a-zA-Z]*$/;
        return (
          (pattern.test(value) && value.length === 3) ||
          "Votre acronyme est composé de 3 lettres."
        );
      },
      date: (value) => {
        if (value) {
          const parsed = parse(value, "dd.MM.yyyy", new Date());
          if (parsed.toString() !== "Invalid Date" && value.length === 10) {
            return true;
          }
          return "Format attendu JJ.MM.AAAA";
        }
        return true;
      },
    },
    roles: [
      "",
      "Collaborateur Ra&D HES",
      "Collaboratrice Ra&D HES",
      "Chargé de Ra&D HES",
      "Chargée de Ra&D HES",
      "Chef de projet Ra&D HES",
      "Cheffe de projet Ra&D HES",
      "Assistant HES académique",
      "Assistante HES académique",
      "Assistant HES de relève",
      "Assistante HES de relève",
      "Stagiaire",
      "Doctorant",
      "Doctorante",
      "Chargé de cours",
      "Chargée de cours",
      "Maître d'enseignement",
      "Professeur HES",
      "Professeure HES",
      "Adjoint scientifique",
      "Adjointe scientifique",
      "Autre",
    ],
    institutes: [
      "",
      "COMATEC",
      "iAi",
      "iE",
      "IICT",
      "IIDE",
      "Insit",
      "MEI",
      "MNT",
      "ReDS",
      "SIM",
      { divider: true },
      { text: "IESE", value: "IESE", disabled: true },
      { text: "IGT", value: "IGT", disabled: true },
    ],
    sections: [
      "",
      "Bibliothèque",
      "Développement & accréditation",
      "Electricité - Electronique - Informatique",
      "Exploitation et conciergerie",
      "Finances Enseignement",
      "Finances Ra&D",
      "Génie civil et géomatique (G)",
      "Infrastructure",
      "Innovation",
      "Langues",
      "Mécanique (M)",
      "Organisation RH",
      "Prestations RH",
      "Projets et services",
      "Secrétariat académique",
      "Secrétariat de Direction",
      "Secrétariat de Direction & Réception",
      "Support, Helpdesk, Service Desk",
      "Système d'information pour la planfication académique",
    ],
    unities: [
      "",
      "CCDR",
      "Comptabilité Enseignement",
      "Comptabilité Formation continue",
      "Comptabilité Ra&D",
      "Conciergerie",
      "Contrôle de gestion analytique et services",
      "Exploitation, maintenance & sécurité",
      "LESBAT",
      "LinguaPro+",
      "Réception",
      "Reprographie",
      "Secértariat de Direction",
    ],
    departments: ["", "DT COMEM+", "DT EC+G", "DT HEG", "DT TIC", "DT TIN"],
    services: [
      "",
      "SE Communication & vie du campus",
      "SE Finances",
      "SE informatique & Systèmes d'information",
      "SE Infrastructures & bâtiments",
      "SE Ressources humaines",
    ],
    centers: [
      "",
      "CE Formation continue",
      "CE Formation de base",
      "CE Ra&D",
      "CE Relations internationales",
    ],
    transversalsupports: [
      "",
      "ST Direction & Réception",
      "ST Développement & accréditation",
      "ST Innovation",
      "ST Secrétariat académique",
      "ST SIPA",
    ],
    directions: ["", "Direction"],
    locations: [
      "",
      "St-Roch",
      "Y-Parc",
      "Cheseaux",
      "Champs-Lovats",
      "AddiPole - Sainte-Croix",
    ],
    sagexs: ["", "PAT", "CII", "CIS", "Prof"],
  }),
};
</script>
